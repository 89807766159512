import { createSlice } from "@reduxjs/toolkit";
import { formatPrice } from "../../components/helpers/formatPrice";

const initialState = {
  data: {
    productQuantity: 0,
    totalPrice: 0
  },

  coin_data: {
    productQuantity: 0,
    totalPrice: 0,
    totalCoins: 0
  },

  coin_total: {
    productQuantity: 0,
    totalPrice: 0,
    coinsToPay: 0,
    gainCoins: 0,
    minusCoins: 0
  }
};

const totalSlice = createSlice({
  name: 'total',
  initialState,
  reducers: {
    updateCart: (state, action) => {
      const cartProducts = action.payload;
      if (cartProducts.length) {
        let productQuantity = cartProducts.reduce((sum, p) => {
          // sum += p.quantity;
          sum++;
          return sum;
        }, 0);

        if (cartProducts.length == 0 && localStorage.getItem("deliverChargeModal") == 1) localStorage.removeItem("deliverChargeModal")

        let totalDiscount = 0, grandTotalSum = 0;

        let totalPrice = cartProducts.reduce((sum, p) => {
          let addonTotal = 0, actualItemRate = parseFloat(p.price), addon_type = '';
          if (p.selectedaddons) {
            p.selectedaddons.map(addonArray => {
              addonTotal += parseFloat(addonArray.price);
              addon_type = addonArray.type;
              return addonTotal;
            });
          }
          if ((p.selectedaddons && p.selectedaddons.length > 0)) {
            sum += addonTotal * p.quantity;
            sum += actualItemRate * p.quantity;
          } else {
            sum += actualItemRate * p.quantity + addonTotal * p.quantity;
          }
          sum = parseFloat(sum);
          formatPrice(sum);
          let discount = parseFloat(p.old_price) - parseFloat(p.price), grandTotal = parseFloat(p.old_price);
          totalDiscount += discount > 0 ? discount * p.quantity : 0;
          grandTotalSum += grandTotal > 0 ? grandTotal * p.quantity : actualItemRate * p.quantity + addonTotal * p.quantity;
          return sum;
        }, 0);

        if (cartProducts.length == 0 && localStorage.getItem("discountModalOpen") == 1) {
          localStorage.removeItem("discountModalOpen");
        }

        let cartTotal = {
          productQuantity: productQuantity,
          totalPrice: totalPrice,
          totalDiscount: totalDiscount,
          grandTotal: grandTotalSum
        };

        state.data = { ...cartTotal }
      } else {
        state.data = {};
      }
    },
    updateCartWhenCoins: (state, action) => {
      const { cartProducts, coin_rate, user } = action.payload
      let productQuantity = cartProducts.reduce((sum, p) => {
        // sum += p.quantity;
        sum++;
        return sum;
      }, 0);

      let totalPrice = cartProducts.reduce((sum, p) => {
        let addonTotal = 0;

        switch (p.coin_type) {
          case 'FULL COIN':
            sum = 0;
            break;
          case 'PARTIAL COIN':
            if (p.selectedaddons) {
              p.selectedaddons.map(addonArray => {
                addonTotal += parseFloat(addonArray.price);
                return addonTotal;
              });
            }
            let amount_in_money = ((p.price * p.coins) / 100)

            sum += amount_in_money * p.quantity + addonTotal * p.quantity;
            break;
          case 'WITHOUT COIN':
            sum = 0;
            break;
          default:
            sum = 0;
            break;
        }

        sum = parseFloat(Math.abs(sum).toFixed(2));
        // formatPrice(sum);
        return sum;
      }, 0);

      let totalCoins = cartProducts.reduce((sum, p) => {
        let addonTotal = 0;
        switch (p.coin_type) {
          case 'FULL COIN':
            if (p.selectedaddons) {
              p.selectedaddons.map(addonArray => {
                addonTotal += parseFloat(addonArray.price);
                return addonTotal;
              });
            }
            sum += ((p.price * p.quantity + addonTotal * p.quantity) / coin_rate);
            break;

          case 'PARTIAL COIN':
            let amount_in_money = ((p.price * p.coins) / 100)
            if (p.selectedaddons) {
              p.selectedaddons.map(addonArray => {
                addonTotal += parseFloat(addonArray.price);
                return addonTotal;
              });
            }
            let total_to_take = ((p.price - amount_in_money) / coin_rate)
            sum += total_to_take * p.quantity + addonTotal * p.quantity;
            break;
          case 'WITHOUT COIN':
            sum = 0
            break;
          default:
            sum = 0
            break;
        }
        sum = parseFloat(Math.abs(sum).toFixed(2));
        // formatPrice(sum);
        return sum;
      }, 0);

      let cartTotal = {
        productQuantity: productQuantity,
        totalPrice: parseFloat(totalPrice).toFixed(0),
        totalCoins: parseFloat(totalCoins).toFixed(0)
      };
      state.coin_data = { ...cartTotal }
      return state;
    },
    updateTotalWhenCoins: (state, action) => {
      const { cartProducts, coin_rate, user, current_coin_rate, earnings } = action.payload
      // console.log('user', user.data, user);
      let coinsToPay = 0, gainCoins = 0;
      let productQuantity = cartProducts.reduce((sum, p) => {
        // sum += p.quantity;
        sum++;
        return sum;
      }, 0);

      let totalPrice = cartProducts.reduce((sum, p) => {
        let addonTotal = 0, actualItemRate = parseFloat(p.price), all_total = 0;
        if (p.selectedaddons) {
          p.selectedaddons.map(addonArray => {
            let total = parseFloat(addonArray.price), coins = 0;
            if (p.coin_type === 'PARTIAL COIN') {
              total = parseFloat(addonArray.price) - ((parseFloat(addonArray.price) * parseFloat(p.coins)) / 100);
              coins = parseFloat(addonArray.price) - total;
            } else if (p.coin_type === 'FULL COIN') {
              total = 0;
              coins = parseFloat(addonArray.price)
            }
            addonTotal += total;
            coinsToPay += coins;
            return addonTotal;
          });
          // all_total = addonTotal * p.quantity;
          // coinsToPay = coinsToPay * p.quantity;
          // console.log(all_total, 'coins check');
        }
        if (p.coin_type === 'PARTIAL COIN' && actualItemRate > 0) {
          actualItemRate = actualItemRate - ((parseFloat(p.price) * parseFloat(p.coins)) / 100);
          coinsToPay += (parseFloat(p.price) - actualItemRate) * p.quantity;
        } else if (p.coin_type === 'FULL COIN' && actualItemRate > 0) {
          actualItemRate = 0;
          coinsToPay += parseFloat(p.price) * p.quantity;
        }
        all_total = (actualItemRate + addonTotal) * p.quantity;
        // coinsToPay = coinsToPay * p.quantity;
        // console.log(coinsToPay, 'coinsToPay check');
        // if (p.gain_coins !== undefined && p.gain_coins.length && user.success === true && user.data !== undefined && user.data.club_user === true) {
        if (p.gain_coins !== undefined && p.gain_coins.length && user.success === true) {
          p.gain_coins.map(coin => {
            if (p.selectedaddons) {
              p.selectedaddons.map(addonArray => {
                if (coin.addon_id == addonArray.addon_id) {
                  gainCoins += parseFloat(coin.coins) * p.quantity;
                }
              });
            } else {
              gainCoins += parseFloat(coin.coins) * p.quantity;
            }
            return coin;
          });
        }
        // console.log(coinsToPay, 'gainCoins', p.name, 'p.name');
        // sum += actualItemRate * p.quantity + addonTotal * p.quantity;
        sum = parseFloat(sum) + all_total;
        return sum.toFixed(0);
      }, 0);

      let minusCoins = coinsToPay;
      coinsToPay = (coinsToPay / parseFloat(coin_rate)).toFixed(0);
      console.log(minusCoins, 'minusCoins', coin_rate, 'coin_rate');

      let cartTotal = {
        productQuantity: productQuantity,
        totalPrice: totalPrice,
        coinsToPay: coinsToPay,
        gainCoins: gainCoins ? gainCoins : 0,
        minusCoins: minusCoins ? minusCoins.toFixed(0) : 0,
      };
      // console.log(cartTotal.coinsToPay, 'cartTotal.coinsToPay');
      const club_features = user?.data?.club_features;
      if (gainCoins > 0 && club_features && club_features.length > 0) {
        if (cartTotal.gainCoins > club_features[0]?.max_coins) {
          cartTotal.gainCoins = club_features[0]?.max_coins;
        }
        if (gainCoins < club_features[0]?.min_coins) {
          cartTotal.gainCoins = club_features[0]?.min_coins;
        }
      } else {
        if (current_coin_rate && current_coin_rate.data) {
          const coinRates = current_coin_rate.data;
          var gainCoinValue = Math.round(cartTotal.gainCoins / coin_rate);
          if (gainCoins > 0 && coinRates.max_gain_coins && gainCoins > parseFloat(coinRates.max_gain_coins)) {
            cartTotal.gainCoins = parseFloat(coinRates.max_gain_coins);
          }
          if (gainCoins > 0 && coinRates.min_gain_basket_size && totalPrice < parseFloat(coinRates.min_gain_basket_size)) {
            cartTotal.gainCoins = 0;
          }
          const minNetEarnings = current_coin_rate && current_coin_rate.data ? parseFloat(current_coin_rate.data.min_net_earnings) : 0;
          if (earnings) {
            if (gainCoinValue > earnings || earnings < minNetEarnings) {
              cartTotal.gainCoins = 0;
            }
          } else {
            const localEarnings = parseFloat(localStorage.getItem("earnings") ?? "0");
            if (gainCoinValue > localEarnings || localEarnings < minNetEarnings) {
              cartTotal.gainCoins = 0;
            }
          }
          if (coinRates.max_redeem_coins && cartTotal.minusCoins > parseFloat(coinRates.max_redeem_coins)) {
            cartTotal.minusCoins = parseFloat(coinRates.max_redeem_coins);
          }
        }
      }
      cartTotal.gainCoins = cartTotal.gainCoins > 0 && cartTotal.gainCoins < 1 ? 1 : Math.round(cartTotal.gainCoins);
      console.log(cartTotal.minusCoins, minusCoins, 'minusCoins coins');
      state.coin_total = { ...cartTotal }
      return state;
    },
  },
  extraReducers: {
  }
})

export const { updateCart, updateCartWhenCoins, updateTotalWhenCoins } = totalSlice.actions

export const getTotalData = ((state) => state.total.data)
export const getTotalCoinData = ((state) => state.total.coin_data)
export const getTotalCoin = ((state) => state.total.coin_total)

export default totalSlice.reducer;